import gsap from 'gsap';

const pageEnter = (container) => {
    let gif = container.querySelectorAll('.gif-tooltip');

    if (gif.length > 0) {
        gif.forEach((item) => {
            let href = item.getAttribute('href');
            item.removeAttribute('href');
            let text = item.textContent;
            let createSpan = document.createElement('span')
            createSpan = createSpan.innerHTML = '<span>'+text+'<img src="'+href+'" class="gif-tooltip__img" title="GIF animatie"></span>'
            item.innerHTML = createSpan;
        });
    }

    const tl = gsap.timeline();

    let menuLayer1 = container.querySelector('.menu__layer1');
    let menuLayer2 = container.querySelector('.menu__layer2');

    tl
        .set(menuLayer1, {clearProps: 'all'})
        .fromTo(menuLayer2, {autoAlpha: '1', height: '100%'}, { autoAlpha: '0', duration: 0.3, ease: 'none'}, 0)
        .set(menuLayer2, {clearProps: 'all'})

    return tl;

}

export default pageEnter;
