const banner = () => {
    $(document).ready(function() {

        // Slick slider banner
        $('.banner__slider').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            arrows: false,
            dots: true,
            fade: true,
            speed: 900,
            infinite: true,
        });
    });

    $(document).ready(function() {
        $('.front__banner__slider').slick({
            autoplay: true,
            autoplaySpeed: 4000,
            dots: false,
            arrows: false,
            infinite: true,
            fade: true,
            cssEase: 'linear',
            pauseOnHover: false,
        });
    });
}

export default banner;
