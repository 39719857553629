import gsap from 'gsap';

const menu = (data) => {

    let menu = data.next.container.querySelector('.menu__layer__logo');

    const tl = gsap.timeline();
    tl
        .to(menu, { autoAlpha: 1 }, 1)


    let frontHeader = data.next.container.querySelector('.front__header');
    let frontHeaderTrigger
    if (frontHeader) {
        frontHeaderTrigger = frontHeader.querySelector('.header__menu__trigger');
        if ( frontHeaderTrigger ) {
            frontHeaderTrigger.addEventListener('click', showMenu);
        }
    }

    let mainContent = data.next.container.querySelector('.main__content');
    let headerMenu
    if ( mainContent ) {
        headerMenu = mainContent.querySelector('.header__menu__trigger');
        if ( headerMenu ) {
            headerMenu.addEventListener('click', showMenu);
        }
    }

    let subHeader = data.next.container.querySelector('.sub__header');
    let subHeaderMenu
    if ( subHeader ) {
        subHeaderMenu = subHeader.querySelector('.header__menu__trigger');
        if ( subHeaderMenu ) {
            subHeaderMenu.addEventListener('click', showMenu);
        }
    }

    function showMenu()
    {

        let menu = data.next.container.querySelector('.menu__block')
        let menuLayer1 = data.next.container.querySelector('.menu__layer1')
        let menuCenterInner = data.next.container.querySelector('.menu__center__inner')
        const tl = gsap.timeline();

        tl
            .to(headerMenu, { pointerEvents: 'none', duration: 0}, 0)
            .to(menuCenterInner, { pointerEvents: 'all', duration: 0}, 0)
            .to(menuLayer1, { height: '100%', duration: 0.5, ease: 'power3.inOut'})
            .to(menu, { autoAlpha: 1, display: 'flex', pointerEvents: 'auto', duration: 0, ease: 'Power3.out'})
            .to(menuLayer1, { y: '100%', duration: 0.5, ease: 'power4.inOut'}, '<')
            .set(menuLayer1, { clearProps: 'all'})
    }

    let headerCloseMenu = data.next.container.querySelector('.menu__close');
    let currentClose = data.next.container.querySelector('.current-menu-item');
    let menuCenterInner = data.next.container.querySelector('.menu__center__inner')
    let headerlogofront = data.next.container.querySelector('.header__logo--front')

    if (headerCloseMenu) {
        headerCloseMenu.addEventListener('click', closeMenu);
    }

    if (currentClose) {
        currentClose.addEventListener('click', closeMenu);
    }

    if (headerlogofront) {
        headerlogofront.addEventListener('click', closeMenu);
    }

    function closeMenu()
    {
        let menu = data.next.container.querySelector('.menu__block')
        const tl = gsap.timeline();

        tl
            .to(menu, { autoAlpha: 0, pointerEvents: 'auto', duration: 0.5, ease: 'Power4.out'})
            .to(menuCenterInner, { pointerEvents: 'all', duration: 0}, 0)
            .to(headerMenu, { clearProps: 'all'} , 0)
            .set(menu, { clearProps: 'all'})
            .set(menuCenterInner, { clearProps: 'all'})
    }

}

export default menu;
