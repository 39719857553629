import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageService = (container) => {

    $('a.service__list__item[href*="#"]:not([href="#"])').on('click',function (e) {
        e.preventDefault();
        let offset = 0;
        let target = this.hash;
        if ($(this).data('offset') != undefined) {
            offset = $(this).data('offset');
        }
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset,
        }, 1200, 'swing', function () {
            // window.location.hash = target;
        });
    });


    let services = container.querySelectorAll('.service__block');

    services.forEach(el => {
        let image = el.querySelector('.service__block__left');
        let content = el.querySelector('.service__block__right');
        const tl = gsap.timeline();
        tl.to(image, {x: '0', autoAlpha: 1, scrollTrigger: {
            trigger: el,
            start: '10% 80%',
            end: '90% 80%',
            scrub: true,
            markers: false,
            }}, 0);

        tl.to(content, {x: '0', autoAlpha: 1, scrollTrigger: {
            trigger: el,
            start: '0 85%',
            end: '90% 85%',
            scrub: true,
            markers: false,
            }}, 0);
    })
}

export default pageService;

