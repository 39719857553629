import barba from '@barba/core';

// Page
import defaultJs from './util/default';
import menu from './util/menu';
import banner from './routes/banner';
import trapFocus from './util/trapFocus';
import pageLeave from './pageTransitions/pageLeave';
import pageEnter from './pageTransitions/pageEnter';
import pageDefault from './pageAnimations/pageDefault';
import pageHome from './pageAnimations/pageHome';
import pageLayerBanner from './pageAnimations/pageLayerBanner';
import pageService from './pageAnimations/pageService';

export default {
    init() {
        barba.init({
            debug: false,
            // define a custom function that will prevent Barba
            // from working on links that contains a `prevent` CSS class
            prevent: ({el}) => el.classList && el.classList.contains('prevent'),

            transitions: [
                {
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                        pageLayerBanner(next.container);
                        pageService(next.container);
                    },
                    after(data) {
                        defaultJs();
                        menu(data);
                        trapFocus(data);
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        pageLayerBanner(data.next.container);
                        pageService(data.next.container);
                        menu(data);
                        trapFocus(data);
                    },
                },
                {
                    name: 'home',
                    to: {
                        namespace: ['home'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                        pageHome(next.container);
                        pageLayerBanner(next.container);
                    },
                    after(data) {
                        defaultJs();
                        menu(data);
                        trapFocus(data);
                        banner();
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        pageHome(data.next.container);
                        pageLayerBanner(data.next.container);
                        menu(data);
                        trapFocus(data);
                        banner();
                    },
                }, {
                    name: 'posts',
                    to: {
                        namespace: ['posts'],
                    },
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container).eventCallback('onComplete', function () {
                            pageDefault(next.container);
                        });
                    },
                    after(data) {
                        // Reset body class
                        defaultJs();
                        menu(data);
                        trapFocus(data);
                    },
                    once(data) {
                        pageEnter(data.next.container).eventCallback('onComplete', function () {
                            pageDefault(data.next.container);
                        });
                        menu(data);
                        trapFocus(data);
                    },
                },
            ],
        });
    },
}
