import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageDefault = (container) => {

    function animateFrom(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function animateScale(elem, delay)
    {
        gsap.to(elem, {
            duration: 1.25,
            delay: delay,
            scale: 1,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto',
        });
    }

    function getTriggerStart()
    {
        let triggerStart = 'top 80%';
        if ($(window).width() < 768) {
            triggerStart = 'top 100%';
        }
        return triggerStart;
    }

    let domainHostingForm = document.querySelector('.domain__hosting__form');
    if(domainHostingForm) {
        let infoIconElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        infoIconElement.setAttribute('viewBox', '0 0 512 512');
        let infoIconElementPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        infoIconElementPath.setAttribute('d', 'M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z');
        infoIconElement.appendChild(infoIconElementPath);

        const watOpzeggenLabel = document.getElementById('field_nqvw5_label');
        const watOpzeggenPopupHTML = document.getElementById('frm_field_32_container');
        watOpzeggenLabel.append(infoIconElement);
        let watOpzeggenPopupSettings = {
            src  : watOpzeggenPopupHTML,
            type : 'html',
        };

        watOpzeggenLabel.addEventListener('click', () => {
            $.fancybox.open(watOpzeggenPopupSettings);
        });

        let infoIconElement2 = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        infoIconElement2.setAttribute('viewBox', '0 0 512 512');
        let infoIconElementPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        infoIconElementPath2.setAttribute('d', 'M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z');
        infoIconElement2.appendChild(infoIconElementPath2);

        const datumOpzeggenLabel = document.getElementById('field_j5zoh_label');
        const datumOpzeggenPopupHTML = document.getElementById('frm_field_33_container');
        datumOpzeggenLabel.append(infoIconElement2);    watOpzeggenPopupHTML
        let datumOpzeggenPopupSettings = {
            src  : datumOpzeggenPopupHTML,
            type : 'html',
        };

        datumOpzeggenLabel.addEventListener('click', () => {
            $.fancybox.open(datumOpzeggenPopupSettings);
        });
    }

    let tinymceFancyboxes = document.querySelectorAll('.fancybox-tinymce');
    if (tinymceFancyboxes.length >= 1) {
        tinymceFancyboxes.forEach((element) => {
            let fancyboxSettings = {
                src  : element.href,
            };

            element.classList.add('prevent');
            element.addEventListener('click', (e) => {
                e.preventDefault();

                $.fancybox.open(fancyboxSettings);
            });
        });
    }

    let refererPopupButton = document.querySelector('.referer__popup__button');
    if (refererPopupButton) {
        refererPopupButton.click();
    }

    let teamNieuwsItem = document.querySelector('.team__single-video');
    if (teamNieuwsItem) {
        let video = teamNieuwsItem.querySelector('video');
        if(video) {
            ScrollTrigger.create({
                trigger: teamNieuwsItem,
                start: 'top 50%',
                end: 'bottom',
                once: false,
                onEnter: function () {
                    if (typeof video !== 'undefined' && video !== null) {
                        video.play();
                    }
                },
                onLeave: function () {
                    if (typeof video !== 'undefined' && video !== null) {
                        video.pause();
                    }
                },
                onEnterBack: function () {
                    if (typeof video !== 'undefined' && video !== null) {
                        video.play();
                    }
                },
                onLeaveBack: function () {
                    if (typeof video !== 'undefined' && video !== null) {
                        video.pause();
                    }
                },
            });
        }
    }


    let teamLayerSection = document.querySelector('.js-team-layer');
    if (teamLayerSection) {
        if ($(window).width() < 768) {
            teamLayerSection.querySelectorAll('.js-team-member').forEach((elem) => {
                let video = elem.querySelector('video');
                if(video) {
                    ScrollTrigger.create({
                        trigger: elem,
                        start: 'top 50%',
                        end: 'bottom',
                        once: false,
                        onEnter: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                            }
                        },
                        onLeave: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                            }
                        },
                        onEnterBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                            }
                        },
                        onLeaveBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                            }
                        },
                    });
                }
            });
        }
    }

    let teamSection = document.querySelector('.team__page__items__wrap');
    if (teamSection) {
        if ($(window).width() < 768) {
            teamSection.querySelectorAll('.js-team-member').forEach((elem) => {
                let video = elem.querySelector('video');
                if(video) {
                    ScrollTrigger.create({
                        trigger: elem,
                        start: 'top 50%',
                        end: 'bottom',
                        once: false,
                        onEnter: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                            }
                        },
                        onLeave: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                            }
                        },
                        onEnterBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.play();
                            }
                        },
                        onLeaveBack: function () {
                            if (typeof video !== 'undefined' && video !== null) {
                                video.pause();
                            }
                        },
                    });
                }
            });
        }
    }

    let sliderTitle = document.querySelector('.js-layer-collection-slider-title');
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (sliderTitle) {
        const sliderTitleWidth = sliderTitle.offsetWidth;
        if (windowWidth < sliderTitleWidth) {
            let tl = gsap.timeline();
            let scrollX = windowWidth - sliderTitleWidth;

            tl.to(sliderTitle, {x: scrollX, scrollTrigger: {
                trigger: '.front-team__title',
                start: 'top 100%',
                end: 'bottom',
                scrub: true,
                markers: false,
            }});
        }
    }

    $(document).ready(function() {
        let section = document.querySelectorAll('.animation__section');
        if (section.length !== false && section) {
            section.forEach((currentSection) => {
                let timeText = 0;
                let timeBlock = 0;
                let timeImage = 0;
                let timeDelay = 0.2;

                currentSection.querySelectorAll('.text__reveal, .image__reveal, .block__reveal').forEach((elem) => {
                    if (elem.classList.contains('text__reveal')) {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateFrom(elem, timeText);
                                timeText = timeText + timeDelay;
                            },
                        });
                    }

                    if (elem.classList.contains('block__reveal')) {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateFrom(elem, timeBlock);
                                timeBlock = timeBlock + timeDelay;
                            },
                        });
                    }

                    if (elem.classList.contains('image__reveal')) {
                        ScrollTrigger.create({
                            trigger: currentSection,
                            start: getTriggerStart(),
                            once: true,
                            onEnter: function () {
                                animateScale(elem, timeImage);
                                timeImage = timeImage + timeDelay;
                            },
                        });
                    }
                });
            });
        }
    });

    container.querySelectorAll('a').forEach(anchor => {
        let href = anchor.getAttribute('href');

        if(href && href.includes('#')) {
            let hash = href.substring(href.indexOf('#')+1);

            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                container.querySelector('#' + hash).scrollIntoView({
                    behavior: 'smooth',
                });
            });
        }
    });

    $('a.footer__back__inner[href*="#"]:not([href="#"])').on('click',function (e) {
        e.preventDefault();
        let offset = 0;
        let target = this.hash;
        if ($(this).data('offset') != undefined) {
            offset = $(this).data('offset');
        }
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset,
        }, 1200, 'swing', function () {
            // window.location.hash = target;
        });
    });

    $('a.front__banner__play__outer[href*="#"]:not([href="#"])').on('click',function (e) {
        e.preventDefault();
        let offset = 0;
        let target = this.hash;
        if ($(this).data('offset') != undefined) {
            offset = $(this).data('offset');
        }
        $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset,
        }, 1000, 'swing', function () {
            // window.location.hash = target;
        });
    });

    // Initiate Fancybox
    $('[data-fancybox]').fancybox({
        buttons: [
            'zoom',
            // 'share',
            // 'slideShow',
            'fullScreen',
            'download',
            // 'thumbs',
            'close',
        ],
    });

    // Play / pause video's on mouseenter / mouseleave
    $('.js-team-member').mouseenter(function () {
        const $video = $(this).find('video');

        if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
            $video.get(0).play();
        }
    }).mouseleave(function () {
        const $video = $(this).find('video');
        if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
            $video.get(0).pause();
        }
    });

    $('.js-team-member').focusin(function () {
        const $video = $(this).find('video');

        if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
            $video.get(0).play();
        }
    }).focusout(function () {
        const $video = $(this).find('video');
        if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
            $video.get(0).pause();
        }
    });

    let contactPage = container.querySelector('.contact__page__form');
    if(contactPage) {
        $(document).ready(function() {
            $('.contact__page__form select').select2({
                minimumResultsForSearch: -1,
            });
        });

        let contactVideo = document.getElementById('video');
        if(contactVideo) {
            contactVideo.play()
        }
    }

    $('.slick-slider').css('opacity', '1');

    $('a[href="#contact"]').on('click', function (e) {
        e.preventDefault();
        const teamLayer = $('.js-team-layer');
        if (teamLayer.length) {
            $('html, body').animate({
                scrollTop: teamLayer.first().offset().top,
            }, 1000)
        }
    });

    // Initiate Slick slider if the viewport smaller then 768px
    if ($(window).width() < 768) {
        $('.js-layer-collection-slider').slick({
            draggable: true,
            arrows: false,
            dots: false,
            speed: 900,
            infinite: false,
            centerMode: true,
            centerPadding: '30px',
            });
    }

    $('.js-layer-collection-slider-wide-team').slick({
        draggable: true,
        swipeToSlide: true,
        true: false,
        prevArrow: $('.js-layer-collection-slider-wide-arrow-prev'),
        nextArrow: $('.js-layer-collection-slider-wide-arrow-next'),
        dots: false,
        speed: 900,
        infinite: false,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 4,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                },
            },
        ],
    });

    $('.js-layer-collection-slider-wide-news').slick({
        draggable: true,
        swipeToSlide: true,
        prevArrow: $('.front-news__slider-arrow--prev'),
        nextArrow: $('.front-news__slider-arrow--next'),
        dots: false,
        speed: 900,
        infinite: false,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                },
            },
        ],
    });



    if($(window).width() < 992) {
        $('.js-layer-collection-slider-methods').slick({
            draggable: true,
            swipeToSlide: true,
            true: false,
            prevArrow: $('.js-layer-collection-slider-methods-arrow-prev'),
            nextArrow: $('.js-layer-collection-slider-methods-arrow-next'),
            dots: false,
            speed: 900,
            infinite: false,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
        });
    }

    $('.js-layer-collection-slider--projects').slick({
        draggable: true,
        swipeToSlide: true,
        true: false,
        prevArrow: $('.js-layer-collection-slider-wide-arrow-prev'),
        nextArrow: $('.js-layer-collection-slider-wide-arrow-next'),
        dots: false,
        speed: 900,
        infinite: false,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1540,
                settings: {
                    slidesToShow: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    variableWidth: false,
                },
            },
        ],
    });

    if ($('html').hasClass('touchevents')) {
        const $contactMember = $('.js-contact-member');
        $('.js-contact-member-toggle').click(function (e) {
            e.preventDefault();
            $contactMember.toggleClass('is-active');
        });

        $(document).click(function (e) {
            if ($(e.target).parents('.js-contact-member').length === 0) {
                $contactMember.removeClass('is-active');
            }
        });
    }

    // Footer toggler
    $(document).ready(function() {
        $('.footer__menu__title').on('click', function () {
            if ($(window).width() <= 991) {
                $(this).children('.footer__menu__title__toggler').toggleClass('active');

                $(this).siblings().fadeToggle();
            }
        });
    });

    // Hide skip tp content button if there is no main content
    const mainContentElement = $('#skip-to-content-anchor');
    const btnSkipToContent = $('.btn--skip');

    console.log(mainContentElement.length);

    if (mainContentElement.length === 0) {
        btnSkipToContent.remove();
    }
}

export default pageDefault;
