import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageHome = (container) => {
    let videoPlayToggleButton = container.querySelector('.front__video__toggle__button');
    const video = $('.front__video__short video').get(0);

    let spinnerToggleButton = container.querySelector('.front-methods__play-button');
    const spinner = $('.front-methods__spinner');

    const frontBannerSlider = $('.front__banner__slider');

    videoPlayToggleButton.addEventListener('click', videoPlayToggle);
    spinnerToggleButton.addEventListener('click', spinnerPlayToggle);

    function videoPlayToggle() {
        if (video.paused) {
            $(this).removeClass('is-paused');
            video.play();
            console.log(frontBannerSlider);
            frontBannerSlider.slick('slickPlay');
        } else {
            $(this).addClass('is-paused');
            video.pause();
            frontBannerSlider.slick('slickPause');
        }
    }

    function spinnerPlayToggle() {
        if ($(this).hasClass('is-paused')) {
            $(this).removeClass('is-paused');
            spinner.css('-webkit-animation-play-state', 'running');
            spinner.css('-moz-animation-play-state', 'running');
            spinner.css('-o-animation-play-state', 'running');
            spinner.css('animation-play-state', 'running');
        } else {
            $(this).addClass('is-paused');
            spinner.css('-webkit-animation-play-state', 'paused');
            spinner.css('-moz-animation-play-state', 'paused');
            spinner.css('-o-animation-play-state', 'paused');
            spinner.css('animation-play-state', 'paused');
        }
    }

    $('.js-layer-collection-slider-wide-team').on('init', function(index, slick){
        if ($(window).width() < 768) {
            slick.slickGoTo(0);
        }

        if ($(window).width() > 768) {
            $('.js-team-member').mouseenter(function () {
                const $video = $(this).find('video');

                if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
                    $video.get(0).play();
                }
            }).mouseleave(function () {
                const $video = $(this).find('video');
                if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
                    $video.get(0).pause();
                }
            });

            $('.js-team-member').focusin(function () {
                const $video = $(this).find('video');

                if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
                    $video.get(0).play();
                }
            }).focusout(function () {
                const $video = $(this).find('video');
                if (typeof $video.get(0) !== 'undefined' && $video.get(0) !== null) {
                    $video.get(0).pause();
                }
            });
        }
    });

    $('.js-layer-collection-slider-wide-team').on('afterChange', function(){
        if ($(window).width() < 768) {
            container.querySelectorAll('.js-layer-collection-slider-wide-team .slick-slide').forEach((elem) => {
                let otherVideo = elem.querySelector('video');
                 if (typeof otherVideo !== 'undefined' && otherVideo !== null) {
                     otherVideo.pause();
                 }
            });

            let currentElement = container.querySelector('.js-layer-collection-slider-wide-team .slick-current');
            let video = currentElement.querySelector('video');
            if(video) {
                ScrollTrigger.create({
                    trigger: $('.js-layer-collection-slider-wide-team'),
                    start: 'top 50%',
                    end: 'bottom',
                    once: false,
                    onEnter: function () {
                        if (typeof video !== 'undefined' && video !== null) {
                            video.play();
                        }
                    },
                    onLeave: function () {
                        if (typeof video !== 'undefined' && video !== null) {
                            video.pause();
                        }
                    },
                    onEnterBack: function () {
                        if (typeof video !== 'undefined' && video !== null) {
                            video.play();
                        }
                    },
                    onLeaveBack: function () {
                        if (typeof video !== 'undefined' && video !== null) {
                            video.pause();
                        }
                    },
                });
            }
        }
    });

    let frontParent = container.querySelector('.front__parent');
    let frontParentLogo = frontParent.querySelectorAll('.header .header__logo__white');

    const tlfrontParent = gsap.timeline({
        scrollTrigger: {
            trigger: frontParent,
            start: 'bottom 40%',
            end: 'top 15%',
            scrub: true,
            markers: false,
        },
    });
    tlfrontParent.from(frontParentLogo, { autoAlpha: 0 }, 0);
    tlfrontParent.to(frontParentLogo, { autoAlpha: 1 }, 1);

    let banner = container.querySelector('.front__banner__black');
    let bannerSubtitle = container.querySelectorAll('.front__banner__subtitle');
    let tl = gsap.timeline();
    tl
        .to(banner, {autoAlpha: 0.6 }, '0')
        .to(bannerSubtitle, {autoAlpha: 1, y: 0, stagger: 0.22})


    // Tab switcher
    $(document).ready(function() {
        $('.front__intro .btn').on('click', function () {
            let key = $(this).attr('data-key');
            $(this).addClass('active');

            $('.front__intro .btn').removeClass('active');
            $('.front__intro [class^="content-"]').removeClass('d-none');

            $('.front__intro [class^="content-"]').hide();
            $('.front__intro .content-'+key).fadeIn();
        });
    });
}

export default pageHome;
